/*
Icon, title, website link
*/

import React from 'react';
import Logo from '../../assets/logo1.png'
import AppIcon from '../AppIcon'

const AppTitleStack = ( props ) => {

    return (
        <div className="flex-1 text-center pt-3 w-0">
            <AppIcon className="m-auto p-5 w-32 h-32 sm:w-40 sm:h-40" app={props.app} />
            <p className="font-bold text-lg mb-6">{props.app.App}</p>
        </div>
    )

}

export default AppTitleStack;