import React from 'react';

const SectionHeader = ( props ) => {

    return (
        <div className="flex bottom-separator bg-gray-100 py-2">
            <p className="flex-1 text-gray-600 font-semibold text-xs text-center uppercase">{props.title}</p>
        </div>
    )


}

export default SectionHeader;