import React from 'react';
import Layout from '../layout/layout';
import AppTitleStack from './appTitleStack'
import RowItem from './rowItem'
import SectionHeader from './sectionHeader'
import Rating from './rating'
import WebsiteItem from './websiteItem.js'

const AppComparisonPage = ({ pageContext }) => {
    const {apps} = pageContext

    const supportFor = (app) => {
        console.log(app.Android_Support);
        return "iOS" + (app.Android_Support === "Yes" ? ", Android" : "")
    }
    
    return (
        <Layout apps={apps}>
            <div className="font-body text-gray-900">
                <div className="flex-col shadow-lg rounded bg-white m-auto w-11/12 max-w-3xl pb-8">
                    <div className="flex">
                        <AppTitleStack app={apps[0]}/>
                        <AppTitleStack app={apps[1]}/>
                    </div>
                    
                    <div className="flex bottom-separator">
                        <WebsiteItem url={apps[0].Website_Link}/>
                        <WebsiteItem url={apps[1].Website_Link}/>
                    </div>

                    <SectionHeader title="Supported Platforms" />

                    <div className="flex bottom-separator py-6">
                        <p className="flex-1 font-normal text-center">{supportFor(apps[0])} </p>
                        <p className="flex-1 font-normal text-center">{supportFor(apps[1])} </p>
                    </div>
                    
                    <SectionHeader title="download price" />

                    <div className="flex bottom-separator py-6">
                        <p className="flex-1 font-normal text-center">{apps[0].Download_Price}</p>
                        <p className="flex-1 font-normal text-center">{apps[1].Download_Price}</p>
                    </div>

                    <SectionHeader title="premium price" />

                    <div className="flex bottom-separator py-6">
                        <p className="flex-1 px-4 font-normal text-center">{apps[0].Premium_pricing}</p>
                        <p className="flex-1 px-4 font-normal text-center">{apps[1].Premium_pricing}</p>
                    </div>
                    
                    <SectionHeader title="app store rating" />

                    <div className="flex bottom-separator py-6">
                        <Rating rating={apps[0].Score} count={apps[0].ReviewCount}/>
                        <Rating rating={apps[1].Score} count={apps[1].ReviewCount}/>
                    </div>

                    <SectionHeader title="features" />

                    <div className="py-4">
                        <RowItem feature="Unlimited Free Habits" 
                            left={apps[0].Number_Free_Habits_Supported === "Unlimited"} 
                            right={apps[1].Number_Free_Habits_Supported === "Unlimited"}/>
                        <RowItem feature="Reminders"
                            left={apps[0].Reminders === "Yes"} 
                            right={apps[1].Reminders === "Yes"}/>
                        <RowItem feature="Schedule Habits"
                            left={apps[0].Schedule_Habits__Specific_Days_ === "Yes"} 
                            right={apps[1].Schedule_Habits__Specific_Days_ === "Yes"}/>
                        <RowItem feature="Share Same Habit With Others"
                            left={apps[0].Share_Same_Habit_With_Others === "Yes"} 
                            right={apps[1].Share_Same_Habit_With_Others === "Yes"}/>
                        <RowItem feature="Habit Notes" left={false} right={true}
                            left={apps[0].Habit_Notes !== "No"} 
                            right={apps[1].Habit_Notes !== "No"}/>
                    </div>

                </div>
            </div>
        </Layout>
    )
}

export default AppComparisonPage;