import React from 'react';

const WebsiteItem = ( props ) => {

    const button = (
        <a className="button px-4 sm:px-12 py-2" 
            href={props.url}>
            Website
        </a>
    )

    const noWebsite = (
        <p className="text-gray-500 px-4">Couldn't find a website.</p>
    )

    return (
        <div className="flex-1 text-center pb-10 m-auto">
            {props.url !== "" ? button : noWebsite}
        </div>
    )

}

export default WebsiteItem;