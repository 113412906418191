import React from 'react';
import Star from '../../assets/star.svg'

const RatingStar = ( props ) => {

    const widthStyle = (v) => {
        return {
            clipPath: "inset(0% " + (1-v)*100 + "% 0% 0%)"
        }
    }
    
    return (
        <div className="m-1 w-4 h-4 relative">
            <Star className="absolute w-full h-full fill-current text-gray-300"></Star>
            <Star className="absolute w-full h-full fill-current text-yellow-500" style={widthStyle(props.value)}></Star>
        </div>
        
    )

}


const Rating = ( props ) => {

    const proportionForValue = (index) => {
        const rating = props.rating
        /*
        turn x/5 rating into individual 0-1 proportions for each star (index 1-5)
        ex. index=3, rating=3.5 => 0.5, index=4, rating=3.5 => 0
        */
        if (index <= rating) {
            return 1;
        }
        else if (index - rating < 1) {
            return rating - Math.floor(rating);
        }
        else {
            return 0;
        }
    }

    return (
        <div className="flex-1 flex-col text-center">
            <div className="flex justify-center">
                <RatingStar value={proportionForValue(1)}/>
                <RatingStar value={proportionForValue(2)}/>
                <RatingStar value={proportionForValue(3)}/>
                <RatingStar value={proportionForValue(4)}/>
                <RatingStar value={proportionForValue(5)}/>
            </div>
            <p className="text-gray-600 text-xs mt-2">{props.count} ratings</p>
        </div>
        
    )
    

}

export default Rating;